import React from "react";
import ReactDOM from "react-dom";

// Main component
const Main = () => (
    <h1>Hello, World</h1>
);

// About component
const About = () => (
    <h2>&copy; Kncok Interactive</h2>
);

// Root element
const rootElement = document.getElementById('root');

// Render components
ReactDOM.render(
    <>
        <Main />
        <About />
    </>,
    rootElement
);
